<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        color="color3"
        text
        v-on="on"
      >edit field</v-btn>
    </template>
    <v-card>
      <v-toolbar color="color1 color1Text--text">
        <v-toolbar-title>Team Field Edit</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="color2 color2Text--text" @click.stop="dialog = false" small fab>
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <loading-bar :value="loading"></loading-bar>
      <v-card-text>
        <div v-if="prefs">Field Preferences: {{prefs.value}}</div>
        <v-select
          :items="fields"
          v-model="myfield"
          label="Field"
          item-text="name"
          item-value="name"
          item-color="color3"
          color="color3"
        ></v-select>
        <div v-if="selectedField">
          {{selectedField.name}}<br>Reserved Courts: {{selectedField.reserved}} | Unreserved: {{selectedField.unreserved}} | Availability {{(selectedSum ? selectedSum.availability : 0) + (selectedField.unreserved * 5)}}
        </div>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          color="success"
          small fab
          :disabled="!dirty"
          :loading="loading"
          @click.stop="save"
        >
          <v-icon>fas fa-save</v-icon>
        </v-btn>
        <v-btn
          color="error"
          small fab
          :disabled="loading"
          @click.stop="dialog=false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['tournament', 'team', 'currentDivision'],
  data () {
    return {
      dialog: false,
      fieldStatus: null,
      myfield: null,
      loading: false
    }
  },
  computed: {
    fields () {
      return this.tournament.props.includes('fields') && this.tournament.jProps && this.tournament.jProps.fields && this.tournament.jProps.fields.filter(f => f.divisionIds.includes(this.currentDivision.id))
    },
    dirty () {
      return this.myfield !== this.team.field
    },
    selectedField () {
      return this.fieldStatus && this.fieldStatus.fields.find(f => f.name === this.myfield)
    },
    selectedSum () {
      return this.selectedField && this.selectedField.summaries.find(f => f.divisionId === this.currentDivision.id)
    },
    prefs () {
      return this.team && this.team.registrationData && this.team.registrationData.extras && this.team.registrationData.extras.find(f => f.name === 'Field Preference')
    }
  },
  methods: {
    getAvail () {
      this.loading = true
      this.$VBL.tournament.getFieldAvail(this.tournament.id)
        .then(r => {
          this.fieldStatus = r.data
        })
        .finally(() => { this.loading = false })
    },
    init () {
      this.getAvail()
      this.myfield = this.team.field
    },
    save () {
      this.loading = true
      this.$VBL.team.fieldChange(this.team.id, this.myfield)
        .then(r => {
          this.dialog = false
        })
        .finally(() => { this.loading = false })
    }
  },
  watch: {
    dialog: function (val) {
      if (val) {
        this.init()
      }
    }
  }
}
</script>
